import React from 'react';
import '../../../assets/sass/_accordion-overwrite.scss';

const youtube = "https://www.youtube.com/watch?v=";
const youtubeLink = {
    en : youtube + "xVLCOCx0-Mc",
    ja : youtube + "MirYJLqQXX0",
    es : youtube + "VNQJQXy4G1E",
    ru : youtube + "_a81VEDxG0w",
    fr : youtube + "YhI6_TxRemA",
    de : youtube + "dX-1AbnEsaU",
    it : youtube + "YheontOEMhU",
    zh : youtube + "8JwgvqgcGHw",
    ko : youtube + "Wk1PwVW2plU",
    ar : youtube + "PQ4KY3fAKYA",
    hi : youtube + "YGZS8tGtxec",
    id : youtube + "LCA4Ay8qIhg",
    pt : youtube + "wYJTgFJOZ3Y",
  }

const bilibiliLink ={
    zh: "ge"
}

export default function FaqAccordionTitle2() {
    return (
        <div >
            <div className="langCng" lang="en">
                <a className="youtubeLink" href={youtubeLink["en"]}>Take a look at YouTube</a>
            </div>

            <div className="langCng" lang="es">
                <a className="youtubeLink" href={youtubeLink["es"]}>Echa un vistazo a YouTube.</a>
            </div>

            <div className="langCng" lang="ru">
                <a className="youtubeLink" href={youtubeLink["ru"]}>Посмотрите на YouTube.</a>
            </div>

            <div className="langCng" lang="fr">
                <a className="youtubeLink" href={youtubeLink["fr"]}>Jetez un oeil à YouTube.</a>
            </div>

            <div className="langCng" lang="de">
                <a className="youtubeLink" href={youtubeLink["de"]}></a>Werfen Sie einen Blick auf YouTube.<a/>
            </div>

            <div className="langCng" lang="it">
                <a className="youtubeLink" href={youtubeLink["it"]}></a>Dai un'occhiata a YouTube.<a/>
            </div>

            <div className="langCng" lang="hi">
                <a className="youtubeLink" href={youtubeLink["hi"]}></a>यूट्यूब पर एक नजर डालें।<a/>
            </div>

            <div className="langCng" lang="id">
                <a className="youtubeLink" href={youtubeLink["id"]}></a>Lihatlah YouTube.<a/>
            </div>

            <div className="langCng" lang="pt">
                <a className="youtubeLink" href={youtubeLink["pt"]}></a>Dê uma olhada no YouTube.<a/>
            </div>

            <div className="langCng" lang="fi">
                <a className="youtubeLink" href={youtubeLink["en"]}></a>Katso YouTubesta.<a/>
            </div>

            <div className="langCng" lang="tr">
                <a className="youtubeLink" href={youtubeLink["en"]}></a>YouTube'a bir göz atın.<a/>
            </div>          

            <div className="langCng" lang="ms">
                <a className="youtubeLink" href={youtubeLink["en"]}></a>Sila lihat di YouTube.<a/>
            </div>

            <div className="langCng" lang="ta">
                <a className="youtubeLink" href={youtubeLink["en"]}></a>யூடியூப்பில் பாருங்கள்.<a/>
            </div>     

            <div className="langCng" lang="ar">
                <a className="youtubeLink" href={youtubeLink["ar"]}></a>الق نظرة على موقع يوتيوب.<a/>
            </div>

            <div className="langCng" lang="ja">
                <a className="youtubeLink" href={youtubeLink['en']}>Youtubeをみてくださいをみてください</a>
            </div>

            <div className="langCng" lang="zh">
                查看 <a className="youtubeLink" href={youtubeLink['zh']}> YouTube</a> 或 <a className="youtubeLink" href={bilibiliLink['zh']}>bilibili</a>
            </div>

            <div className="langCng" lang="ko">
                <a className="youtubeLink" href={youtubeLink['ko']}>YouTube 또는 bilibili을보세요.</a>
            </div>
        </div>
    );
  }