import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Social from '../components/Social';

import FaqHeader from '../components/header/FaqHeader';
import ContactText from '../components/otherlanguage/ContactText';
import FaqAccordionTitle1 from '../components/otherlanguage/faq/FaqAccordionTitle1';
import FaqAccordionContant1 from '../components/otherlanguage/faq/FaqAccordionContant1';
import FaqAccordionTitle2 from '../components/otherlanguage/faq/FaqAccordionTitle2';
import FaqAccordionContant2 from '../components/otherlanguage/faq/FaqAccordionContant2';
import FaqText from '../components/otherlanguage/FaqText';
import '../assets/sass/_masthead.scss';
import Accordion from 'react-bootstrap/Accordion';
import '../assets/sass/_accordion-overwrite.scss';

const faq = () => (
  <Layout>
    <FaqHeader />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="my-auto">
            <div className="header-content mx-auto">

              <section className="faq" id="faq">
                
                <div className="pagetitle ">
                  <FaqText/>
                </div>
                <div>
                  {/* <FaqAccordion/> */}
                  <div>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <FaqAccordionTitle1/>
                        </Accordion.Header>
                          <Accordion.Body>
                            <FaqAccordionContant1/>
                          </Accordion.Body>
                        </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <FaqAccordionTitle2/>
                        </Accordion.Header>
                          <Accordion.Body>
                            <FaqAccordionContant2/>
                          </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          <ContactText/>
        </h2>
        <Social />
      </div>
    </section>
    <Footer />
  </Layout>
);

export default faq;
